@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "./common/style/market.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
.isall {
  background: #7f76bd;
  color: #fff;
}

.reward {
  padding: 32px 8px;

  .must {
    position: relative;
  }
  .must::after {
    content: "*";
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    color: red;
  }
  &-left {
    display: flex;
    &-title {
      position: relative;
      width: 80px;
      height: 24px;
      line-height: 24px;
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      background: #eceafa;
      border-radius: 4px;
      margin-right: 36px;
    }
  }
  &-btn1 {
    @include center(24px);
    width: 96px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 4px;
    color: #f394ac;
    border: 1px solid #f394ac;
    margin-right: 20px;
  }
  &-btn2 {
    @include center(26px);
    width: 96px;
    cursor: pointer;
    background: #fae1ee;
    color: #333333;
    border-radius: 4px;
    margin-right: 20px;
  }
  &-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
    font-size: 14px;
    &-title {
      width: 70px;
      height: 24px;
      line-height: 24px;
      position: relative;
      padding: 0 28px 0 18px;
      font-size: 14px;
    }
    &-title::before {
      content: "";
      height: 24px;
      width: 4px;
      background: #eceafa;
      border-radius: 12px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
    }
    &-title::after {
      content: "*";
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
      color: red;
    }
  }
  &-settingList {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
  }
  &-addbtn {
    margin: 0 auto;
    cursor: pointer;
  }
  &-addbtn2 {
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
}
